import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function RulesModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        onClick={handleShow}
        style={{ borderRadius: "0px" }}
        variant="white"
        className="py-3 w-100 text-primary w-md-auto px-6 me-md-4 mt-3 my-lg-0 my-md-0"
      >
        Our rules
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-primary text-white" closeVariant="white">
          <Modal.Title>Our rules</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-primary">
          <h2 className="line-heading text-white mb-4 fs-1">Course rules</h2>
          <p className="text-white">Local Rules & Course Map</p>
          <h3 className="text-white">Out of Bounds (Rule 18.2)</h3>
          <ol type="a">
            <li className="text-white">
              Beyond any fence, hedge or stake bounding the course.
            </li>
            <li className="text-white">
              In the car park, the Clubhouse or its paved surround.
            </li>
            <li className="text-white">
              In the ditch to the left of the 7th, 10th and 16th holes and to
              the rear of the 7th green.
            </li>
            <li className="text-white">
              The practice ground as defined by the cut area.
            </li>
          </ol>
          <h3 className="text-white">Penalty Areas (Rule 17)</h3>
          <p className="text-white">
            Ordinary penalty areas are denoted by yellow stakes or lines (Rules
            17.1d(1) and (2)).
          </p>
          <p className="text-white">
            Lateral penalty areas are denoted by red stakes or lines (Rule
            17.1d(3)).
          </p>
          <p className="text-white">
            Unless defined by lines or stakes, the margin of the penalty area is
            the edge of the water.
          </p>
          <h3 className="text-white">
            Integral Parts of the Course (Rule 8.1)
          </h3>
          <p className="text-white">
            The following are integral parts of the course. The ball must be
            played as it lies or declared unplayable (Rule 19).
          </p>
          <ol type="a">
            <li className="text-white">All bridges on the course.</li>
            <li className="text-white">
              The sleeper and log headwalls at the lake on holes 12 and 15.
            </li>
            <li className="text-white">
              The log retaining wall at the stream on the 4th hole.
            </li>
          </ol>
          <h3 className="text-white">
            Movable Obstructions and Loose Impediments (Rule 15)
          </h3>
          <p className="text-white">
            If a player's ball is in a bunker, he/she may move both movable
            obstructions "artificial objects" and loose impediments "natural
            objects" (stones, leaves, twigs, etc). If the ball moves when moving
            a moveable obstruction, the player is not penalized. However, if the
            ball moves when moving a loose impediment, your ball must be
            replaced on its original spot and you get a one penalty stroke. Rule
            15.1b applies.
          </p>
          <h3 className="text-white">Staked Trees</h3>
          <p className="text-white">
            Young trees identified by stakes are no play zones:
          </p>
          <p className="text-white">
            If a player's ball lies anywhere on the course other than in a
            penalty area and it lies on or touches such a tree or such a tree
            interferes with the player's stance or area of intended swing, the
            ball MUST be lifted, without penalty and dropped within one
            club-length not nearer the hole. Rule 16.1f applies.
          </p>
          <h3 className="text-white">Overhead Wires</h3>
          <p className="text-white">
            A ball striking the wires, or supporting pole, MUST be replayed,
            without penalty, using Rule 14.6. The original stroke does not
            count. Posts and stays supporting the lines are immovable
            obstructions and qualify only for relief under Rule 16.1.
          </p>
          <h3 className="text-white">Immovable Obstructions (Rule 16.1)</h3>
          <p className="text-white">
            These are artificial objects which are not movable i.e. signage
            posts, fixed yardage posts, sprinkler heads, etc.
          </p>
          <h3 className="text-white">Roads and Paths (Rule 16)</h3>
          <p className="text-white">
            All artificially surfaced roads and paths are immovable obstructions
            (Rule 16.1a applies). The ball may be played as it lies, or relief
            may be taken without penalty by lifting and dropping the ball within
            one club-length of and not nearer the hole than the nearest point of
            relief.
          </p>
          <h3 className="text-white">Fixed Sprinkler Heads (Rule 16)</h3>
          <p className="text-white">
            All fixed sprinkler heads are immovable obstructions and relief
            without penalty is available under Rule 16.1b. In addition, if a
            ball lies in the general area and an immovable obstruction on or
            within two club-lengths of the putting green and within two
            club-lengths of the ball intervenes on the line of play between the
            ball and the hole,{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-primary">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RulesModal;
