import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import lottie from "lottie-web";
import parse from "html-react-parser";
import { Card, Carousel } from "react-bootstrap";
import HomeHero from "../components/home-hero";
import StarLogo from "../images/icons/star.svg";
import FacebookLogo from "../images/bi_facebook.svg";
import XLogo from "../images/logo 1.svg";
import InstaLogo from "../images/Frame 62.svg";
import ContactForm from "../components/contactForm";
import BumperBanner from "../components/numbersSection";
import ImageCarousel from "../components/changingImage";
import RulesModal from "../components/rulesModal";
import Reviews from "../components/reviews";
import MenuModal from "../components/menuModal";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviews {
					author
					datePublished
					source
					reviewBody
				}
			}

			heroImg: wpMediaItem(title: { eq: "Hero-Home" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpMonthlySpecial {
				monthlySpecialFields {
					title
					description
					button {
						link
						text
					}
					logo {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}

			Img0: wpMediaItem(title: { eq: "index-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pantry1: wpMediaItem(title: { eq: "Pantry" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pantry2: wpMediaItem(title: { eq: "overview-8" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			pantry3: wpMediaItem(title: { eq: "overview-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tanishq1: wpMediaItem(title: { eq: "tanishq1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tanishq2: wpMediaItem(title: { eq: "overview-13" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tanishq3: wpMediaItem(title: { eq: "overview-11" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			Img3: wpMediaItem(title: { eq: "Contact-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1: wpMediaItem(title: { eq: "index-4" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			gallery2: wpMediaItem(title: { eq: "index-5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			gallery3: wpMediaItem(title: { eq: "index-6" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4: wpMediaItem(title: { eq: "index-7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5: wpMediaItem(title: { eq: "index-8" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6: wpMediaItem(title: { eq: "index-9" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7: wpMediaItem(title: { eq: "index-10" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery1b: wpMediaItem(title: { eq: "new2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery2b: wpMediaItem(title: { eq: "new5" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery3b: wpMediaItem(title: { eq: "new7" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery4b: wpMediaItem(title: { eq: "new9" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery5b: wpMediaItem(title: { eq: "new13" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery6b: wpMediaItem(title: { eq: "new3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gallery7b: wpMediaItem(title: { eq: "new1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			scorecard: wpMediaItem(title: { eq: "Scorecard-good-quality" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			menuPage1: wpMediaItem(title: { eq: "Pantry Page 1 Updated" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			menuPage2: wpMediaItem(title: { eq: "Pantry Page 2 Updated" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			# allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
			#   nodes {
			#     localFile {
			#       childImageSharp {
			#         gatsbyImageData(
			#           formats: [AUTO, WEBP]
			#           quality: 100
			#           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			#           layout: CONSTRAINED
			#           placeholder: BLURRED
			#         )
			#       }
			#     }
			#     caption

			#     permalink
			#     timestamp
			#   }
			# }

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const pantryImages = [data.pantry1, data.pantry2, data.pantry3];
	const tanishqImages = [data.tanishq1, data.tanishq2, data.tanishq3];
	const menuImages = [data.menuPage1, data.menuPage2];
	const scorecardImage = data.scorecard;
	const [currentSlides, setCurrentSlides] = useState([0, 0, 0, 0, 0, 0, 0]);

	const updateSlides = (index) => {
		setCurrentSlides((prevSlides) => {
			const updatedSlides = [...prevSlides];
			updatedSlides[index] = updatedSlides[index] === 1 ? 0 : 1;
			return updatedSlides;
		});

		setTimeout(() => {
			const nextIndex = (index + 1) % currentSlides.length;
			updateSlides(nextIndex);
		}, 1500);
	};

	useEffect(() => {
		updateSlides(0);
	}, []);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Toot Hill Golf Course | No Ordinary Course"
				description="Experience golfing excellence in Toot Hill, Essex at our picturesque course. Immerse yourself in our manicured fairways and challenging greens."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Toot Hill Golf Course | No Ordinary Course",
					description:
						"Experience golfing excellence in Toot Hill, Essex at our picturesque course. Immerse yourself in our manicured fairways and challenging greens.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<HomeHero
						title={
							<>
								WELCOME TO
								<br /> ECF CAR CARE
							</>
						}
						video="https://toot-hill-golf.rjmdigital.net/wp-content/uploads/2023/10/Toot-Hill-Golf-Club-Website-Banner-v1.mp4"
					/>
					<section className="py-4 py-md-5 py-lg-7 bg-primary">
						<Container>
							<Row className="justify-content-center">
								<Col className="text-center" lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										Welcome to Toot Hill
									</p>
									<h2 className="line-heading text-white mb-4 fs-1">
										We welcome all golfers
									</h2>
									<p className="text-white text-center">
										Rolling hills, carefully placed bunkers and water hazards,
										including a spectacular island green par 3 12th hole all add
										to the challenges that inspire golfers of all standards on
										each and every hole.
									</p>
									<p className="text-white text-center">
										For pure escapism and sheer enjoyment there is no course to
										rival Toot Hill. Visitors to Toot Hill cannot help but to
										enjoy their day, they will marvel at the condition of the
										course, the breathtaking views, the peacefulness and they
										will be itching to come back soon.
									</p>
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										href="https://bookings.toothillgolfclub.co.uk/"
										target="_blank"
										rel="noreferrer"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
									>
										Book a tee time
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<BumperBanner image={scorecardImage} />
					{data.wpMonthlySpecial?.monthlySpecialFields?.title.length > 0 && (
						<section className="py-4 py-md-5 py-lg-7 bg-white">
							{" "}
							<Container>
								<Row className="justify-content-center">
									<Col className="text-center" lg={7}>
										<GatsbyImage
											className="mb-3"
											style={{ maxWidth: "100px" }}
											image={
												data.wpMonthlySpecial?.monthlySpecialFields?.logo
													.localFile.childImageSharp.gatsbyImageData
											}
											alt={
												data.wpMonthlySpecial?.monthlySpecialFields.logo.altText
											}
										/>
										<h2 className="  text-primary mb-3 display-5">
											{data.wpMonthlySpecial?.monthlySpecialFields.title}
										</h2>
										<div className="text-primary">
											{parse(
												data.wpMonthlySpecial?.monthlySpecialFields.description
											)}
										</div>
										<Button
											style={{ borderRadius: "0px" }}
											variant="outline-primary"
											target="_blank"
											rel="noreferrer"
											href={
												data.wpMonthlySpecial?.monthlySpecialFields.button.link
											}
											className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-3 px-6 "
										>
											{data.wpMonthlySpecial?.monthlySpecialFields.button.text}
										</Button>
									</Col>
								</Row>
							</Container>
						</section>
					)}
					<section className="py-4 py-md-5 py-lg-7 bg-white">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center">
								<Col lg={6}>
									<GatsbyImage
										className="w-100"
										image={data.Img0.localFile.childImageSharp.gatsbyImageData}
										alt={data.Img0.altText}
									/>
								</Col>
								<Col lg={6}>
									<h2 className="  text-primary mb-3 display-5">Golf Pros</h2>
									<p className="text-primary">
										Our skilled golf pros bring expertise and passion to enhance
										your game. Join us for personalized lessons and tips to
										elevate your swing.
									</p>
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-primary"
										as={Link}
										to="/golf-pros"
										className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-3 px-6 "
									>
										Find out more
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7 bg-primary">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center">
								<Col lg={6}>
									<h2 className=" text-white mb-3 display-5">
										Join us in the new Pantry
									</h2>
									<p className="text-white">
										Our new Pantry is now open everyday from 7:30am for all
										guests. Available for breakfast or lunch before or after
										your round.
									</p>
									<MenuModal images={menuImages} />
									{/* <Button
                    style={{ borderRadius: "0px" }}
                    variant="outline-white"
                    as={Link}
                    target="_blank"
                    rel="noreferrer"
                    href="https://tanishqs.co.uk"
                    className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-4 px-6 "
                  >
                    Menu
                  </Button> */}
								</Col>
								<Col lg={6}>
									{/* <GatsbyImage
                    className="w-100"
                    image={data.Img1.localFile.childImageSharp.gatsbyImageData}
                    alt={data.Img1.altText}
                  /> */}

									<Carousel controls={true} interval={5500} indicators={true}>
										{pantryImages.map((imageData, index) => (
											<Carousel.Item key={index}>
												<GatsbyImage
													className="mb-3"
													style={{ height: "626px" }}
													image={
														imageData.localFile.childImageSharp.gatsbyImageData
													}
													alt={imageData.altText}
												/>
											</Carousel.Item>
										))}
									</Carousel>
									{/* <Carousel controls={true} interval={3000} indicators={true}>
                    <Carousel.Item>
                      <GatsbyImage
                        className=" mb-3"
                        style={{ height: "626px" }}
                        image={
                          data.Img1.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={data.Img1.altText}
                      />
                    </Carousel.Item>

                    <Carousel.Item>
                      <GatsbyImage
                        className="mb-3"
                        style={{ height: "626px" }}
                        image={
                          data.Img1.localFile.childImageSharp.gatsbyImageData
                        }
                        alt={data.Img1.altText}
                      />
                    </Carousel.Item>


                  </Carousel> */}
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7 bg-white">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center">
								<Col xs={{ span: 12, order: 2 }} lg={6}>
									<Carousel controls={true} interval={5500} indicators={true}>
										{tanishqImages.map((imageData, index) => (
											<Carousel.Item key={index}>
												<GatsbyImage
													className="mb-3"
													style={{ height: "626px" }}
													image={
														imageData.localFile.childImageSharp.gatsbyImageData
													}
													alt={imageData.altText}
												/>
											</Carousel.Item>
										))}
									</Carousel>
									{/* <GatsbyImage
                    className="w-100"
                    image={data.Img2.localFile.childImageSharp.gatsbyImageData}
                    alt={data.Img2.altText}
                  /> */}
								</Col>
								<Col xs={{ span: 12, order: 1 }} lg={6}>
									<h2 className="text-primary mb-3 display-5">Tanishq</h2>
									<p className="text-primary">
										If you’re looking for a special end to your golf experience
										then why not try out Tanishq. The bar is open 12pm onwards
										and the restaurant is open 5pm for dinner, Tanishq is a
										modern Indian fine dining.
									</p>
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-primary"
										as={Link}
										target="_blank"
										rel="noreferrer"
										href="https://tanishqs.co.uk"
										className="py-3 w-100  w-md-auto mb-lg-4 mb-1 mt-3 px-6 "
									>
										Visit website
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7 bg-primary">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										toot hill customer reviews
									</p>
									<h2 className="line-heading text-center text-white mb-5 fs-1">
										Our latest course reviews
									</h2>
								</Col>
							</Row>
							<Reviews reviewsData={data.reviews.reviews} />
							<Col>
								<div className="text-center">
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										as={Link}
										target="_blank"
										rel="noreferrer"
										href="https://maps.app.goo.gl/x1e7R3cru789py5v5"
										className="py-3 w-100 white-link w-md-auto mb-4 mb-md-0 mt-5 px-6 "
									>
										Leave a review
									</Button>
								</div>
							</Col>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										Where we are
									</p>
									<h2 className="line-heading-dark text-center text-dark-green mb-5 fs-1">
										How to find Toot Hill Golf Club
									</h2>
								</Col>
							</Row>

							<Row>
								<Col>
									<iframe
										className="position-relative google-map"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2473.0311425588225!2d0.1911667763615362!3d51.695875196917385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d897e47c2e754f%3A0xb7f81d8f596b4b47!2sToot%20Hill%20Golf%20Club!5e0!3m2!1sen!2suk!4v1695664623164!5m2!1sen!2suk"
										width="100%"
										style="border:0;"
										allowfullscreen=""
										loading="lazy"
										referrerpolicy="no-referrer-when-downgrade"
									></iframe>
									<div className="text-center">
										<Button
											style={{ borderRadius: "0px" }}
											variant="outline-primary"
											target="_blank"
											rel="noreferrer"
											href="https://maps.google.com/maps/dir//Toot+Hill+Golf+Club+School+Rd+Toot+Hill+Ongar+CM5+9PU/@51.6958719,0.1937417,17z/data=!4m5!4m4!1m0!1m2!1m1!1s0x47d897e47c2e754f:0xb7f81d8f596b4b47"
											className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-5 px-6 "
										>
											Get directions
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					{/* <section className="py-5 bg-primary py-lg-7">
            <Container>
              <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                <Col lg={8}>
                  <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    follow us @toot_hill
                  </p>
                  <h2 className="line-heading text-center text-white mb-5 fs-1">
                    Our latest Instagram posts
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="g-5">
                    {data.allInstagramContent.nodes.map((post) => (
                      <Col md={6} lg={4}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={post.permalink}
                          className="text-decoration-none"
                        >
                          <div className="rect-img-container">
                            <GatsbyImage
                              className="w-100 rect-img"
                              image={
                                post.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={post.caption}
                            />
                          </div>
                        </a>
                      </Col>
                    ))}
                  </Row>
                  <div className="text-center mt-5 d-flex justify-content-center align-items-center">
                    <a href="#">
                      <img
                        src={InstaLogo}
                        alt="Instagram Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                    <a href="#">
                      <img
                        src={FacebookLogo}
                        alt="Facebook Logo"
                        className=" me-4  d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                    <a href="#">
                      <img
                        src={XLogo}
                        alt="X Logo"
                        className=" me-4 d-inline-block "
                        style={{
                          maxWidth: "100px",
                        }}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}

					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary mb-4 fs-1">Gallery</h2>
									<p className="mb-4">
										See more of our exceptional 18 hole Essex golf course which
										is available all year round
										<br className="d-none d-lg-block" /> with something to offer
										for golfers of all level.
									</p>
								</Col>
							</Row>
							<Row className="d-none d-md-flex">
								<Col md={6} lg={4} className="g-3">
									<ImageCarousel
										images={[data.gallery1, data.gallery1b]}
										height="310px"
										activeslide={currentSlides[0]}
									/>
									<ImageCarousel
										images={[data.gallery2, data.gallery2b]}
										height="310px"
										activeslide={currentSlides[1]}
									/>
									<ImageCarousel
										images={[data.gallery3, data.gallery3b]}
										height="310px"
										activeslide={currentSlides[2]}
									/>
								</Col>
								<Col md={6} lg={4} className="g-3">
									<ImageCarousel
										images={[data.gallery4, data.gallery4b]}
										height="310px"
										activeslide={currentSlides[3]}
									/>
									<ImageCarousel
										images={[data.gallery5, data.gallery5b]}
										height="636px"
										activeslide={currentSlides[4]}
									/>
								</Col>
								<Col lg={4} className="g-3 d-none d-lg-block">
									<ImageCarousel
										images={[data.gallery6, data.gallery6b]}
										height="636px"
										activeslide={currentSlides[5]}
									/>
									<ImageCarousel
										images={[data.gallery7, data.gallery7b]}
										height="310px"
										activeslide={currentSlides[6]}
									/>
								</Col>
							</Row>
							<Row className=" d-md-none">
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3 d-lg-none"
										image={
											data.gallery6.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery6.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery1.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery1.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery2.altText}
									/>
								</Col>
								<Col xs={6} lg={4} className="g-3">
									<GatsbyImage
										className="w-100 mb-3 d-lg-none"
										style={{ height: "160px" }}
										image={
											data.gallery3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery3.altText}
									/>
									<GatsbyImage
										className="w-100 mb-3"
										style={{ height: "160px" }}
										image={
											data.gallery4.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery4.altText}
									/>
									<GatsbyImage
										style={{ height: "336px" }}
										className="w-100 mb-3"
										image={
											data.gallery5.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.gallery5.altText}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									{" "}
									<div className="text-center">
										<Button
											style={{ borderRadius: "0px" }}
											variant="outline-primary"
											as={Link}
											to="/course-overview"
											className="py-3 w-100  w-md-auto mb-4 mb-md-0 mt-4 px-6 "
										>
											Course overview
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="py-4 py-md-5 py-lg-7 bg-primary">
						<Container>
							<Row className="justify-content-center">
								<Col className=" text-white text-center" lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										Our rules
									</p>
									<h2 className="line-heading text-white mb-4 fs-1">
										Course rules
									</h2>
									{/* <p className="text-white">
										Lorem ipsum dolor sit amet consectetur adipisicing elit.
										Tenetur, similique harum ut nam atque error incidunt
										aspernatur nobis, sint, molestiae nihil voluptatem omnis
										aperiam molestias voluptates vel facere aut repellendus!
									</p> */}

									{/* <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                    Our rules
                  </p>
                  <h2 className="line-heading text-white mb-4 fs-1">
                    Course rules
                  </h2>
                  <p className="text-white">Local Rules & Course Map</p>
                  <h3 className="text-white">Out of Bounds (Rule 18.2)</h3>
                  <ol type="a">
                    <li className="text-white">
                      Beyond any fence, hedge or stake bounding the course.
                    </li>
                    <li className="text-white">
                      In the car park, the Clubhouse or its paved surround.
                    </li>
                    <li className="text-white">
                      In the ditch to the left of the 7th, 10th and 16th holes
                      and to the rear of the 7th green.
                    </li>
                    <li className="text-white">
                      The practice ground as defined by the cut area.
                    </li>
                  </ol>
                  <h3 className="text-white">Penalty Areas (Rule 17)</h3>
                  <p className="text-white">
                    Ordinary penalty areas are denoted by yellow stakes or lines
                    (Rules 17.1d(1) and (2)).
                  </p>
                  <p className="text-white">
                    Lateral penalty areas are denoted by red stakes or lines
                    (Rule 17.1d(3)).
                  </p>
                  <p className="text-white">
                    Unless defined by lines or stakes, the margin of the penalty
                    area is the edge of the water.
                  </p>
                  <h3 className="text-white">
                    Integral Parts of the Course (Rule 8.1)
                  </h3>
                  <p className="text-white">
                    The following are integral parts of the course. The ball
                    must be played as it lies or declared unplayable (Rule 19).
                  </p>
                  <ol type="a">
                    <li className="text-white">All bridges on the course.</li>
                    <li className="text-white">
                      The sleeper and log headwalls at the lake on holes 12 and
                      15.
                    </li>
                    <li className="text-white">
                      The log retaining wall at the stream on the 4th hole.
                    </li>
                  </ol>
                  <h3 className="text-white">
                    Movable Obstructions and Loose Impediments (Rule 15)
                  </h3>
                  <p className="text-white">
                    If a player's ball is in a bunker, he/she may move both
                    movable obstructions "artificial objects" and loose
                    impediments "natural objects" (stones, leaves, twigs, etc).
                    If the ball moves when moving a moveable obstruction, the
                    player is not penalized. However, if the ball moves when
                    moving a loose impediment, your ball must be replaced on its
                    original spot and you get a one penalty stroke. Rule 15.1b
                    applies.
                  </p>
                  <h3 className="text-white">Staked Trees</h3>
                  <p className="text-white">
                    Young trees identified by stakes are no play zones:
                  </p>
                  <p className="text-white">
                    If a player's ball lies anywhere on the course other than in
                    a penalty area and it lies on or touches such a tree or such
                    a tree interferes with the player's stance or area of
                    intended swing, the ball MUST be lifted, without penalty and
                    dropped within one club-length not nearer the hole. Rule
                    16.1f applies.
                  </p>
                  <h3 className="text-white">Overhead Wires</h3>
                  <p className="text-white">
                    A ball striking the wires, or supporting pole, MUST be
                    replayed, without penalty, using Rule 14.6. The original
                    stroke does not count. Posts and stays supporting the lines
                    are immovable obstructions and qualify only for relief under
                    Rule 16.1.
                  </p>
                  <h3 className="text-white">
                    Immovable Obstructions (Rule 16.1)
                  </h3>
                  <p className="text-white">
                    These are artificial objects which are not movable i.e.
                    signage posts, fixed yardage posts, sprinkler heads, etc.
                  </p>
                  <h3 className="text-white">Roads and Paths (Rule 16)</h3>
                  <p className="text-white">
                    All artificially surfaced roads and paths are immovable
                    obstructions (Rule 16.1a applies). The ball may be played as
                    it lies, or relief may be taken without penalty by lifting
                    and dropping the ball within one club-length of and not
                    nearer the hole than the nearest point of relief.
                  </p>
                  <h3 className="text-white">
                    Fixed Sprinkler Heads (Rule 16)
                  </h3>
                  <p className="text-white">
                    All fixed sprinkler heads are immovable obstructions and
                    relief without penalty is available under Rule 16.1b. In
                    addition, if a ball lies in the general area and an
                    immovable obstruction on or within two club-lengths of the
                    putting green and within two club-lengths of the ball
                    intervenes on the line of play between the ball and the
                    hole,{" "}
                  </p> */}

									<RulesModal />
									{/*
                  <Button
                    style={{ borderRadius: "0px" }}
                    variant="white"
                    as={Link}
                    to="/"
                    className="py-3 w-100 text-primary w-md-auto mb-4 mb-md-0 mt-4 px-6 me-md-4 "
                  >
                    Book a tee time
                  </Button> */}
									<Button
										style={{ borderRadius: "0px" }}
										variant="outline-white"
										as={Link}
										to="/course-overview"
										className="py-3 w-100 white-link w-md-auto mb-4 px-6 px-md-5 px-lg-5 mt-3 my-lg-0 my-md-0"
									>
										Course overview
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-4 py-md-5 py-lg-7">
						<Container>
							<Row className="g-5 gx-lg-7 align-items-center justify-content-center">
								<Col lg={8}>
									<p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
										WANT TO GET IN TOUCH?
									</p>
									<h2 className="line-heading-dark text-center  text-dark-green mb-5 fs-1">
										Send a message
									</h2>
								</Col>
							</Row>

							<Row className="g-5 justify-content-between align-items-center h-100 gx-lg-7  gx-xl-0">
								<Col styl={{ minHeight: "100%" }} lg={6} xl={5}>
									<div
										className="h-100"
										style={{ borderRadius: "8px", overflow: "hidden" }}
									>
										<GatsbyImage
											style={{ borderRadius: "8px" }}
											className="w-100 h-100"
											image={
												data.gallery5.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.gallery5.altText}
										/>
									</div>
								</Col>
								<Col lg={6} xl={5}>
									<ContactForm />
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
