import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Modal, Button, Carousel } from "react-bootstrap";

function MenuModal({ images }) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				onClick={handleShow}
				style={{ borderRadius: "0px" }}
				variant="white"
				className="py-3 w-100 text-primary w-md-auto px-6 me-md-4 mt-3 my-lg-0 my-md-0"
			>
				Menu
			</Button>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header
					closeButton
					className="bg-primary text-white"
					closeVariant="white"
				>
					<Modal.Title>Pantry menu</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-primary">
					<Carousel indicators={false} controls={true} variant="dark">
						{images.map((image, index) => (
							<Carousel.Item key={index}>
								<GatsbyImage
									className="w-100"
									image={image.localFile.childImageSharp.gatsbyImageData}
									alt={image.altText}
								/>
							</Carousel.Item>
						))}
					</Carousel>
				</Modal.Body>
				<Modal.Footer className="bg-primary">
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default MenuModal;
