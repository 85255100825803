import React, { useRef } from "react";
import Col from "react-bootstrap/Col";
import { Button, Container, Row } from "react-bootstrap";
import useOnScreen from "./use-on-screen";
import CountUp from "react-countup";
import { Link } from "gatsby";
import ScorecardModal from "../components/scorecardModal";

const BumperBanner = ({ image }) => {
  const ref1 = useRef();
  const onScreen1 = useOnScreen(ref1, "0px");

  return (
    <section className="py-4 py-md-5 py-lg-7 bg-white">
      <Container ref={ref1}>
        <Row className="g-5 gx-lg-7">
          <Col lg={5}>
            <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
              18 hole golf course
            </p>
            <h2 className=" text-primary mb-3 ">
              Course Information about Toot Hill Golf Course
            </h2>
            <p>
              The condition of the course and the maturity of the trees give the
              impression that the course is many years older than it actually
              is. Construction of the course started in 1989 and it was open for
              play in September 1991.
            </p>
            <ScorecardModal image={image} />
          </Col>
          <Col lg={7}>
            <Row className="g-0">
              <Col
                className="text-center"
                style={{ borderRight: "1px solid #014C46" }}
                xs={6}
              >
                <p className="display-5 pb-0 mb-0 text-dark-green merriweather">
                  {onScreen1 && <CountUp start={0} end={18} />}
                </p>
                <p className="fs-2 text-dark-green pb-0 mb-0">Holes</p>
                <div
                  className="my-4 w-100 bg-primary"
                  style={{ height: "1px" }}
                ></div>
                <p className="display-5 pb-0 mb-0 text-dark-green merriweather">
                  {onScreen1 && <CountUp start={5000} end={6254} />}
                </p>
                <p className="fs-2 text-dark-green">Yards</p>
              </Col>
              <Col className="text-center" xs={6}>
                <p className="display-5 pb-0 mb-0 text-dark-green merriweather">
                  {onScreen1 && <CountUp start={0} end={70} />}
                </p>
                <p className="fs-2 text-dark-green pb-0 mb-0">Par</p>
                <div
                  className="my-4 w-100 bg-primary"
                  style={{ height: "1px" }}
                ></div>
                <p className="display-5 pb-0 mb-0 text-dark-green merriweather">
                  {onScreen1 && (
                    <CountUp start={1900} end={1991} separator="" />
                  )}
                </p>
                <p className="fs-2 text-dark-green">Opened</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* <Container ref={ref1} className="">
        <Row className="">
          <Col className="text-center pb-5">
            <h2>Split the cost of your repairs with Bumper</h2>
          </Col>
        </Row>
        <Row>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              {onScreen1 && <CountUp start={10} end={0} />}%
            </h3>
            <p className="fs-3">
              Interest-free
              <br /> payments
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              £{onScreen1 && <CountUp end={5} />}k
            </h3>
            <p className="fs-3">
              Up to £5,000
              <br /> credit limit
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              1-{onScreen1 && <CountUp end={6} />}
            </h3>
            <p className="fs-3">
              Split payments
              <br /> over 1 - 6 months
            </p>
          </Col>
          <Col className="text-center" lg={3}>
            <h3 className="text-primary display-2">
              {onScreen1 && <CountUp start={5} end={2} />}
            </h3>
            <p className="fs-3">
              2-click application.
              <br /> Quick and fast
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center pt-5">
            <a
              className="fs-4 inter-bold black-link "
              target="_blank"
              rel="noreferrer"
              href="https://www.bumper.co.uk/"
            >
              Apply now with Bumper
            </a>
          </Col>
        </Row>
      </Container> */}
    </section>
  );
};

export default BumperBanner;
