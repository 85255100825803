import React, { useState } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import StarLogo from "../images/icons/star.svg";

const starCount = 5;

function Review({ review, minHeight }) {
	const [showFullText, setShowFullText] = useState(false);

	const toggleText = () => {
		setShowFullText(!showFullText);
	};

	const shortenText = (text) => {
		if (text.length > 140) {
			const lastSpaceIndex = text.lastIndexOf(" ", 140);
			return `${text.substring(0, lastSpaceIndex)}...`;
		}
		return text;
	};

	return (
		<Col xl={4} md={8}>
			<div
				style={{ minHeight: minHeight }}
				className="bg-dark-green py-5 px-6 text-center position-relative"
			>
				<h3 className="text-uppercase text-white fs-5 pb-3">{review.author}</h3>
				{review.reviewBody.length > 140 && (
					<p className="text-white pb-4">
						{showFullText ? review.reviewBody : shortenText(review.reviewBody)}
						{review.reviewBody.length > 140 && (
							<a
								className="white-link"
								style={{ cursor: "pointer" }}
								onClick={toggleText}
							>
								<br /> {showFullText ? " Read less" : " Read more"}
							</a>
						)}
					</p>
				)}
				{review.reviewBody.length <= 140 && (
					<p className="text-white pb-4">{review.reviewBody}</p>
				)}

				<div className="d-flex w-100 position-absolute  start-0 bottom-0 pb-6 justify-content-center mt-2">
					{Array.from(Array(starCount), (_, index) => (
						<img
							key={index}
							src={StarLogo}
							alt="Star"
							className={`d-inline-block ${
								index !== starCount - 1 ? "me-2" : ""
							}`}
							style={{
								maxWidth: "20px",
							}}
						/>
					))}
				</div>
			</div>
		</Col>
	);
}

const Reviews = ({ reviewsData }) => {
	return (
		<>
			<Row className="d-none d-xl-flex mx-auto gy-3">
				{reviewsData?.slice(0, 3).map((review, i) => (
					<Review minHeight="350px" key={i} review={review} />
				))}
			</Row>
			<Carousel indicators={false} className="d-block d-md-none">
				{reviewsData?.slice(0, 3).map((review, i) => (
					<Carousel.Item key={i}>
						<Row className="justify-content-center">
							<Review minHeight="350px" review={review} />
						</Row>
					</Carousel.Item>
				))}
			</Carousel>
			<Carousel indicators={false} className="d-none d-md-block d-lg-none">
				{reviewsData?.slice(0, 3).map((review, i) => (
					<Carousel.Item key={i}>
						<Row className="justify-content-center">
							<Review minHeight="300px" review={review} />
						</Row>
					</Carousel.Item>
				))}
			</Carousel>
			<Carousel indicators={false} className="d-none d-lg-block d-xl-none">
				{reviewsData?.slice(0, 3).map((review, i) => (
					<Carousel.Item key={i}>
						<Row className="justify-content-center">
							<Review minHeight="280px" review={review} />
						</Row>
					</Carousel.Item>
				))}
			</Carousel>
		</>
	);
};

const ReviewStar = () => (
	<img
		src={StarLogo}
		alt="Star"
		className=" me-2 d-inline-block "
		style={{
			maxWidth: "20px",
		}}
	/>
);

export default Reviews;
